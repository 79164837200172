
























































































































































































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import { currencyFormat, loadWidget } from "@/utils/helpers";
import { mixins } from "vue-class-component";
import WidgetMixins from "@/mixins/WidgetMixins";
import { namespace } from "vuex-class";
import ToolBar from "@/components/widgets/ToolBar.vue";
import { IGraphData, INotifications } from "@/types/types";
import moment from "moment";
//firebase
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import UAParser from "ua-parser-js";
import { dateFormat } from "@/filters/currencyFormat";

const appModule = namespace("apps");
const deliveryRequestModule = namespace("deliveryRequests");
const ordersModule = namespace("orders");
const notificationModule = namespace("notifications");

@Component({
  components: {
    ToolBar,
    Notifications: loadWidget("pages/Notifications"),
    EmptyPage: loadWidget("pages/EmptyPage"),
    "internet-connection": loadWidget("pages/InternetConnection"),
    ProgressBar: loadWidget("widgets/CircularProgressLoader"),
    SnackBar: loadWidget("widgets/SnackBar")
  },
  filters: {
    currencyFormat,
    dateFormat
  }
})
export default class Applications extends mixins(WidgetMixins) {
  placeholder: string = require("@/assets/images/notification-list.jpg");
  @appModule.Getter("getApps") dashboard!: Array<object>;
  @ordersModule.Getter("getDashboardCount") dashboardCount!: any;
  @ordersModule.Getter("getDashboardGraph") dashboardGraph!: any;
  @deliveryRequestModule.Getter("getRequestStats") requestStats!: any;
  @notificationModule.Getter("getAllNotifications")
  notifications!: ReadonlyArray<INotifications>;

  showPushNotificationToast = false;
  markAsRead: boolean = true;
  tab: any = null;
  text: string = "sample test";
  items: Array<string> = ["All Updates", "Popular"];
  show: boolean = false;
  date: string = new Date().toISOString().substr(0, 10);
  menu2: boolean = false;
  areaChartOptions: any = {};
  chartOptions: any = {
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded"
      }
    },
    colors: ["#e20639", "#04bf5b"],
    legend: {
      show: true,
      fontSize: "11px",
      fontWeight: "500",
      fontFamily: "Montserrat, sans-serif"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          cssClass: "ft"
        }
      },
      tooltip: {
        enabled: true,
        formatter: (val: any, opts: any) => {
          return moment(val).format("Do MMMM,YYYY");
        }
      }
    },
    yaxis: {
      title: {
        text: "Total Orders"
      },
      labels: {
        show: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          cssClass: "ft"
        },
        formatter: (val: any) => {
          return val;
        }
      },
      tooltip: {
        enabled: true
      }
    },
    fill: {
      opacity: 1
    }
  };

  series: any = [
    {
      name: "Total Order Count",
      data: []
    },
    {
      name: "Total Order Amount (GHC)",
      data: []
    }
  ];

  isNotificationTurnedOn = false;
  isNotificationDenied = false;
  headers: any = [
    {
      text: "Date",
      align: "start",
      sortable: false,
      value: "name"
    },
    { text: "Customer", value: "calories" },
    { text: "Products", value: "fat" },
    { text: "Details", value: "carbs" },
    { text: "Price", value: "protein" },
    { text: "Delivery Cost", value: "iron" }
  ];

  emptyState: any = {
    notification: require("@/assets/images/music.png"),
    popular: require("@/assets/images/commerce-and-shopping.png")
  };

  @Watch("dashboardGraph")
  onDashboardGraphChange(payload: any): void {
    const xAxis = [
      ...new Set([
        ...payload.totalCountOfOrdersByDate.map(
          (item: IGraphData) => item.label
        ),
        ...payload.totalAmountOfOrdersByDate.map(
          (item: IGraphData) => item.label
        )
      ])
    ];
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        categories: xAxis
      }
    };
    this.areaChartOptions = {
      ...this.chartOptions,
      chart: {
        type: "area",
        height: 350
      },
      xaxis: {
        categories: xAxis
      }
    };
    this.series[0].data = payload.totalCountOfOrdersByDate.map(
      (item: IGraphData) => item.value
    );
    this.series[1].data = payload.totalAmountOfOrdersByDate?.map(
      (item: IGraphData) => item.value
    );
  }

  @Watch("dashboardCount")
  onDashboardCountChange(payload: any): void {}

  // turning on notifications here
  turnOnNotifications(): void {
    this.showPushNotificationToast = false;
    if (firebase.messaging.isSupported()) {
      Notification.requestPermission()
        ?.then(response => {
          switch (response) {
            case "granted":
              this.isNotificationTurnedOn = true;
              this.getNotificationToken();
              break;
            case "denied":
              this.showPushNotificationToast = false;
              this.isNotificationDenied = true;
              this.$store.dispatch(
                "showSnackBar",
                {
                  snackBar: true,
                  message: `Permission denied, browser won't be able to send push notifications`
                },
                { root: true }
              );
              break;
            case "default":
              break;
          }
        })
        .catch(error => {});
    }
  }

  // getting notifications token
  getNotificationToken(): void {
    const messaging = firebase.messaging();
    messaging
      ?.getToken({
        vapidKey: process.env.VUE_APP_FCM_VAPID_KEY
      })
      .then(currentToken => {
        this.sendTokenToServer(currentToken);
      })
      .catch(error => {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              error ?? `Unable to register this browser for push notifications`
          },
          { root: true }
        );
      });
  }

  //sending notifications to server
  sendTokenToServer(currentToken: string): void {
    const parser = new UAParser(window.navigator.userAgent.toLowerCase());
    const brave = (window.navigator as any)?.brave;

    let payload = {
      browser:
        brave && brave.isBrave.name === "isBrave"
          ? "brave"
          : parser.getBrowser().name?.toLowerCase(),
      token: currentToken
    };

    this.$store.dispatch("auth/registerNotificationTokens", payload);
    const query = `?page=1&size=20`;
    this.$store.dispatch("notifications/list", { query });
  }

  removeNotification(id: string): void {
    this.$store.dispatch("notifications/removeNotifications", { id });
  }

  clearAllNotifications(): void {
    this.$store.dispatch("notifications/clearAllNotifications");
  }

  markNotificationAsRead(notificationId: string): void {
    this.$store.dispatch(`notifications/markAsRead`, { id: notificationId });
  }

  created() {
    this.$store.dispatch("auth/me");
    this.$store.dispatch("orders/dashboardCount");
    this.$store.dispatch("orders/dashboardGraph");

    if (!("navigator" in window)) {
      this.$store.dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: `Browser doesn't support push notifications`,
          status: "red"
        },
        { root: true }
      );
    }
    switch (Notification.permission) {
      case "default":
        this.showPushNotificationToast = true;
        break;
      case "granted":
        this.isNotificationTurnedOn = true;
        this.$store.dispatch("notifications/list", {
          query: `?page=1&size=20`
        });
        break;
      case "denied":
        this.isNotificationDenied = true;
        break;
    }
  }
}
